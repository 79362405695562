.blogdetail-page-container{
    display: flex;
    flex-direction: column;
    background: linear-gradient(116.64deg, #f37e20 32.94%, #ad1e23 136.53%);
}

.blogdetail-form-container {
    width: 80%;
    margin: auto;
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    margin-top: 100px;
    margin-bottom: 40px;
    padding-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .headingContainerblogdetail{
    line-height: 1.6;
    color:  rgb(68, 71, 91);
    margin: auto;
    font-size: 34px;
    text-align: center;
    width: 100%;
    padding-bottom: 10px;
  }

  .terms-boxBlogDetail {
    line-height: 1.6;
    color: #555;
    margin: auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
  }

  .textBlogDetail {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .textBlogDetail img{
    max-height: 600px;
    max-width: 93%;
    display: flex;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 10px;
  }

  .blogDetailImg {
    width: 90%;
    max-height: 600px;
    object-fit: contain;
    border-radius: 10px;
  }
  

@media (max-width: 768px) {
    .blogdetail-page-container {
      flex-direction: column;
      align-items: center;
    }

    .blogdetail-form-container {
        width: 90%;
        padding-top: 20px;
        margin-bottom: 20px;
        flex-direction: column;
      }

      .headingContainerblogdetail{
        line-height: 1.6;
        color:  rgb(68, 71, 91);
        margin: auto;
        width: 100%;
        font-size: 28px;
        padding-bottom: 5px;
      }

      .terms-boxBlogDetail {
        line-height: 1.6;
        margin-bottom: 20px;
        width: 100%;
        padding: 10px;
      }
      .textBlogDetail img{
        max-height: 200px;
        max-width: 100%;
        box-sizing: border-box;
        margin-inline:auto ;
        object-fit: contain;
        border-radius: 10px;
      }
}
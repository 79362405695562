.loan-page-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(116.64deg, #f37e20 32.94%, #ad1e23 136.53%);
  }
  
  .loancontainer {
    display: flex;
    width: 70%;
    margin: auto;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-top: 80px;
    flex-wrap: wrap;
  }
  
  .loan-content {
    max-width: 100%;
    flex-basis: 50%;
    padding: 10px;
  }
  
  .loan-heading {
    font-size: 2.8rem;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .loan-description {
    font-size: 1.2rem;
    color: #f3f3f3;
    width: 90%;
    line-height: 30px;
    margin-bottom: 20px;
  }
  
  .buttoncont{
 display:flex;
 justify-content: right;

  } 
  .apply-button {
    background: linear-gradient(135deg, #FF6A00, #D84315);
    color: #fff;
    cursor: pointer;
    transition: background 0.6s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 8px 15px rgba(255, 127, 80, 0.4);
    border: none;
    padding: 10px 20px;
    font-size: 1.5rem;
    border-radius: 5px;
  }
  
  .apply-button:hover {
    background: #D32F2F; /* Hover color */
    transform: scale(1.05); /* Slight scaling on hover */
    box-shadow: 0 12px 20px rgba(255, 127, 80, 0.6); /* Stronger shadow effect on hover */
  }
  
  
  
  .loan-image {
    max-width: 100%;
    flex-basis: 40%;
    padding: 10px;
  }
  
  .loan-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .contact-info {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-details {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    font-size: 16px;
  }
  
  .loan-form-container {
    width: 80%;
    margin: auto;
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    margin-bottom: 40px;
    padding-top: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contactformfirst{
width: 80%;
margin: auto;
  }

  .loan-form-box {
    padding: 20px;
    width: 50%;
    box-sizing: border-box;
    border-radius: 8px;
  }
  
  .loan-form-heading {
    margin-bottom: 30px;
    color: #ad1e23;
    font-weight: 500 !important;
  }
  
  .loan-form-description {
    color: #666;
    line-height: 35px !important;
    margin-top: 20px !important;
    font-size: 18px !important;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .loan-page-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contactformfirst{
        width: 100%;
    }
    .loancontainer {
      width: 100%;
    }
    .loan-form-box{
        width: 100%;
        padding-top: 0;
    }
  
    .loan-content, .loan-image {
      flex-basis: 100%;
      padding: 10px;
      text-align: center;
    }
  
    .loan-heading {
      font-size: 2rem;
    }
  
    .loan-description {
      font-size: 1rem;
    }
  
    .apply-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    .loan-form-container {
      width: 90%;
      padding-top: 20px;
      margin-bottom: 20px;
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
    .loan-heading {
      font-size: 1.5rem;
    }
  
    .loan-description {
      font-size: 0.9rem;
    }
  
    .apply-button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  }
  
/* Second Section */

  .containerLoan {
    max-width: 75%;
    margin: 0 auto;
    padding: 20px;
    /* padding-Block:20px; */
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .headingLoan {
    padding-top: 10px;
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    /* color: #c91c1c; */
    color: #fff;
  }
  .headingContainerLoan{
    line-height: 1.6;
    color:  rgb(68, 71, 91);
    margin: auto;
    width: 100%;
    padding-bottom: 10px;
  }

  .terms-boxLoan {
    line-height: 1.6;
    color: #555;
    margin: auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .terms-boxLoan img{
    max-height: 600px;
    max-width: 93%;
    display: flex;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 10px;
  }
  

  
  
  .listLoan {
    padding-left: 20px;
  }
  
  @media (max-width: 768px) {

      .headingLoan {
        margin-top: 0;
        text-align: center;
        font-size: 32px;
        margin-bottom: 20px;
      }

      .headingContainerLoan{
        line-height: 1.6;
        color:  rgb(68, 71, 91);
        margin: auto;
        width: 100%;
        padding-bottom: 5px;
      }
    
      .terms-boxLoan {
        line-height: 1.6;
        margin-bottom: 20px;
        width: 100%;
        padding: 10px;
      }
      .terms-boxLoan img{
        max-height: 200px;
        max-width: 100%;
        box-sizing: border-box;
        object-fit: contain;
        border-radius: 10px;
      }
  }
 
  

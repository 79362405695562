body {
  margin: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
  scrollbar-width: none;
  /* For Firefox */
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Edge */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #FF0000;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #FF0000;
  border-radius: 50%;
  cursor: pointer;
}

p,
/* h1, */
h2,
h3,
h4,
h5,
h6 {
  text-align: justify;
}
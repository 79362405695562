.bank-section {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 100px;
    background-color: #fff;
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); */
  }
  
  .bankheading{
font-weight: 600;
font-size: 36px;
color: #e60000;
margin-bottom: 20px;
  }

  .marquee-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
  }
  
  .marquee {
    display: flex;
    animation: marquee 20s linear infinite;
  }
  
  .bank-item {
    position: relative;
    margin: 0 40px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .bank-image-container {
    position: relative;
    overflow: hidden;
    width: 200px;
    height: 200px;
    background-color: #EBF6F9;
    border:1px solid #d3d3d3;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .bank-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    transition: transform 0.3s ease, opacity 0.3s ease, filter 0.3s ease;
  }
  
  .bank-detail {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    color: #1b1a1a;
    border: 2px solid red;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .bank-item:hover .bank-image {
    transform: scale(1.1); /* Zoom effect on hover */
    opacity: 0.8; /* Slightly fade out the image */
    filter: blur(4px); /* Apply blur on the image */
  }
  
  .bank-item:hover .bank-detail {
    opacity: 1; /* Show the detail text */
    display: flex; /* Ensure flex layout is active on hover */
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
  }
  
  /* Keyframe for scrolling */
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @media (max-width: 768px) {
    .bank-section {
      padding: 40px 20px;
    }
    .bank-item {
      margin: 0 20px;
    }
  }
  
.container {
    max-width: 75%;
    margin: 0 auto;
    padding: 20px;
    /* padding-Block:20px; */
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .heading {
    padding-top: 100px;
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
    /* color: #c91c1c; */
    color: #fff;
  }
  .headingContainer{
    line-height: 1.6;
    color:  rgb(68, 71, 91);
    margin: auto;
    width: 80%;
    padding-bottom: 10px;
  }

  .terms-box {
    line-height: 1.6;
    color: #555;
    margin: auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 80%;
    background: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .text {
    margin-bottom: 15px;
  }
  
  .list {
    padding-left: 20px;
  }
  
  @media (max-width: 768px) {
    .container {
        max-width: 80%;
      }
 
      .heading {
        padding-top: 80px;
        text-align: center;
        font-size: 32px;
        margin-bottom: 20px;
        color: #e60000;
      }

      .headingContainer{
        line-height: 1.6;
        color:  rgb(68, 71, 91);
        margin: auto;
        width: 100%;
        padding-bottom: 5px;
      }
    
      .terms-box {
        line-height: 1.6;
        margin-bottom: 20px;
        width: 100%;
        padding: 10px;
      }
      
  }
 
  

.footer {
  color: #fff;
  padding: 1rem 1rem;
  background: #2b2928;
  padding-top: 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  flex: 1 1 calc(20% - 1rem);
  min-width: 200px;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: .5rem;
  color: #fff;
  border-bottom: 2px solid #fff;
  display: inline-block;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section a:hover {
  color: #fff;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
  border-top: 1px solid #fff;
  padding-top: 1rem;
  color: #fff;
}

.footer-bottom a {
  color: #fff;
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

.logoimgfooter {
  width: 300px;
  margin: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 25px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
    min-width: auto;
  }

  .logoimgfooter {
    margin-top: 20px;
  }

}
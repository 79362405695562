.detail-section {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 100px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping on desktop */
  gap: 20px;
}
.content1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping on desktop */
  gap: 20px;
}

.text-content {
  flex: 1;
  max-width: 600px;
  margin-right: 20px;
}

.heading {
  font-weight: 600;
  font-size: 36px;
  color: #e60000;
  margin-bottom: 20px;
}

.subheading {
  font-weight: 500;
  font-size: 24px;
  color: #555;
  margin-bottom: 15px;
}

.text {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

@media (max-width: 768px) {
  .detail-section {
    padding: 20px 20px;
  }

  .content {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .content1 {
    flex-direction: column;
    gap: 20px;
  }

  .text-content {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .image-content {
    width: 100%;
  }

  .image {
    width: 100%;
  }
}

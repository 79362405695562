.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px;
  height: calc(100vh - 80px);
  /* background: linear-gradient(to right, #ff7e5f, #feb47b); */
  /* box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); Soft shadow for the section */
  /* box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); */
}

.text-container {
  max-width: 50%;
  text-align: left;
  color: white;
  z-index: 1;
}

.headingHero {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #00b894, #2ecc71);
  /* Green gradient */
  -webkit-background-clip: text;
  /* Clips the background gradient to the text */
  color: #e60000;
}

.subheadingHero {
  font-size: 20px;
  margin-bottom: 20px;
  color: #000;
  /* Lighter color for the subheading */
  line-height: 1.5;
}

.button {
  padding: 15px 30px;
  font-size: 18px;
  background: linear-gradient(135deg, #FF0000, #000000);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 8px 15px rgba(255, 127, 80, 0.4);
}

.button:hover {
  background-color: #cc0000;
  transform: translateY(-3px);
  /* Slight lift effect on hover */
}

.image-container {
  max-width: 50%;
  z-index: 0;
  text-align: center;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .hero-section {
    height: auto;
    flex-direction: column;
    padding: 40px 50px;
  }

  .text-container {
    max-width: 100%;
    text-align: center;
    padding-top: 40px;
    margin-bottom: 30px;
  }

  .headingHero {
    font-size: 35px;
  }

  .subheadingHero {
    font-size: 18px;
  }

  .button {
    font-size: 16px;
    padding: 12px 25px;
  }

  .image-container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 30px 20px;
  }

  .headingHero {
    font-size: 30px;
  }

  .subheadingHero {
    font-size: 16px;
  }

  .button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .image {
    width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .headingHero {
    font-size: 28px;
  }

  .subheadingHero {
    font-size: 16px;
  }

  .button {
    font-size: 12px;
    padding: 8px 15px;
  }

  .image {
    width: 100%;
  }
}
.applicationdetail-page-container {
    display: flex;
min-height: 100lvh;
    flex-direction: column;
    background: linear-gradient(116.64deg, #f37e20 32.94%, #ad1e23 136.53%);
  }
  
  .applicationdetail-form-container {
    width: 80%;
    margin: auto;
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    
    /* margin-top: 100px;
    margin-bottom: 40px; */
    padding-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }
  
  .headingContainerapplicationdetail {
    line-height: 1.6;
    color: rgb(68, 71, 91);
    margin: auto;
    font-size: 34px;
    text-align: center;
    width: 100%;
    padding-bottom: 10px;
  }
  
  .terms-boxapplicationDetail {
    line-height: 1.6;
    color: #555;
    margin: auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    /* padding: 20px; */
    margin-top: 20px;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .applicationdetail-page-container {
      flex-direction: column;
      align-items: center;
      min-height: auto;
    }
  
    .applicationdetail-form-container {
      width: 100%;
      margin-top: 100px;
      padding-top: 20px;
      margin-bottom: 20px;
      flex-direction: column;  /* Stack in column on mobile */
    }
  
    .headingContainerapplicationdetail {
      line-height: 1.6;
      color: rgb(68, 71, 91);
      margin: auto;
      width: 100%;
      font-size: 28px;
      padding-bottom: 5px;
    }
  
    .terms-boxapplicationDetail {
      line-height: 1.6;
      margin-bottom: 20px;
      width: 100%;
      padding: 10px;
    }
  
    /* For mobile: Ensure the OTP fields and button are stacked vertically */
    .MuiBox-root {
      flex-direction: column;  /* Stack input fields and button in column on mobile */
      width: 100%;
    }
  }
  
  /* For desktop: Align Application ID and Send OTP button in a row */
  @media (min-width: 769px) {
    .MuiBox-root {
      display: flex;
      flex-direction: row;  /* Keep in row for desktop */
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .MuiTextField-root {
      margin-right: 10px;
      width: 200px;
    }
  
    button {
      height: 100%;
    }
  
    .MuiButton-root {
      margin-top: 0;
    }
  }
  
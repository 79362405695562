/* General styles */
body {
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  color: #e60000;
  border-bottom: 1px solid #d4d4d4;;
  box-sizing: border-box;
  z-index: 9999;
  position: fixed;
  width: 100%;
}

.logo h1 {
  margin: 0;
}

.logoImg{
 width: 210px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: right;
  flex: 1;
}

.nav-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-list li {
  margin: 0 20px;
  position: relative;
}

.nav-list li a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
}
.nav-list li a:hover {
  color: #e60000;
}

.services {
  cursor: pointer;
}
.services:hover .sub-menu {
  display: block;
}

.services:hover .arrow {
  transform: rotate(135deg); /* Arrow points up */
}

.sub-menu {
  display: none;
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
  margin: 0;
  z-index: 1000;
}

.sub-menu li {
  padding: 10px 0;
  border-bottom: 1px solid #d4d4d4;
  white-space: nowrap;
}

.sub-menu li a {
  color: #000;
  text-decoration: none;
}

.sub-menu.open {
  display: block;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  height: 3px;
  width: 30px;
  background-color: #000;
  margin: 4px 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
  }

  .nav.open {
    display: block;
  }

  .nav-list {
    flex-direction: column;
    padding: 20px;
  }

  .nav-list li {
    margin: 10px 0;
  }

  .hamburger {
    display: flex;
  }

  .services .sub-menu {
    position: relative;
    top: 0;
  }
}
/* Style for the arrow */
.arrow {
  display: inline-block;
  margin-left: 10px;
  width: 8px;
  height: 8px;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}


/* When the sub-menu is open, rotate the arrow to point up */
.arrow.up {
  transform: rotate(135deg); /* Rotates the arrow to point upwards */
}

/* When the sub-menu is closed, the arrow points down */
.arrow.down {
  transform: rotate(315deg); /* Arrow pointing down */
}

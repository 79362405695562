.blogmaincontainer{
  display: flex;
    flex-direction: column;
    background: linear-gradient(116.64deg, #f37e20 32.94%, #ad1e23 136.53%);
}

.blog img {
  width: 100%;
  height: 100%;
}
.blog .text {
  padding: 40px 30px;
}
.blog h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
}
.blog h1:hover {
  color: #e60000;
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  color: #e60000;
}
.blog span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.blog span:nth-last-child(1) label {
  color: #e60000;
}
.blog p {
  color: grey;
}


.container {
  max-width: 80%;
  margin: auto;
  padding: 20px;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

/* button {
  padding: 10px 20px;
  background-color: #fff;
  color: #e60000;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}
button:hover {
  background-color: #e60000;
  color: #fff;
} */

/*------------Heading Section-----------*/
#heading {
  text-align: center;
  margin-top: 100px;
  /* padding: 40px 0; */
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #e60000;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 36px;
  margin: 20px 0;
  text-transform: capitalize;
}

/*------------Blog Section-----------*/
.blog {
  /* padding: 60px 0; */
  /* background-color: #fff; */
  padding-bottom: 20px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.items {
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}
.items .img img {
  /* width: 100%;
  height: auto;
  display: block; */
  width: 100%;
  margin-top: 20px;
  height: 200px; /* Fixed height for images */
  object-fit: cover; 
}
.items .text {
  padding: 20px;
}
.items .text h1 {
  font-size: 22px;
  margin: 15px 0;
}
.items .text p {
  font-size: 16px;
  line-height: 1.5;
}
.items .admin span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #888;
}

/*------------Responsive Styles-----------*/
@media screen and (max-width: 1024px) {
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid2 {
    grid-template-columns: 1fr;
  }

  #heading h1 {
    font-size: 28px;
  }
  
}

@media screen and (max-width: 480px) {
  .container {
    max-width: 90%;
    /* padding: 0 10px; */
  }

  #heading h1 {
    font-size: 24px;
  }
}
/* CardCarousel.css */
.carousel-section {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  height: auto; /* Adjust height as needed */
}

.heading-section {
  width: 50%;
  background-color: #ad1e23; /* Blue background */
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing:border-box;
  text-align: center;
  padding: 20px;
  font-size: 20px;
}

.carousel-container {
  display: flex;
  width: 50%;
  margin-left: -10%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card-carousel {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 90%; /* Adjust this as needed */
  margin: 0;
  cursor: pointer;
}

.card {
  width: 90px; /* This will allow 4 cards to fit in one view */
  margin: 0 10px;
  text-align: left;
  padding: 10px 20px;
  color: rgb(69, 68, 68);
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover{
  transform: scale(1.02);
  background: #ad1e23;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.card-selected{
  transform: scale(1.02);
  background: #ad1e23;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.arrow-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 30px;
  z-index: 10;
  color: #333;
}

.arrow-btn.left {
  position: absolute;
  left: 10px;
}

.arrow-btn.right {
  position: absolute;
  right: 10px;
}

.arrow-btn:hover {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .carousel-section {
flex-direction: column;
  }

  .heading-section {
    width: 100%;
    background-color: #ad1e23; 
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: 100px;
    font-size: 20px;
    height: 60px;
  }
  
  .carousel-container {
    width: 100%;
    background-color: #ad1e23;
    margin-left: 0px;
    padding-bottom: 20px;
  }

  .card{
text-align: center;
  }

  .card:hover{
    background: #f37e20;
  }
  
}
.headingBlognew{
  color: #ad1e23;
  display: flex;
  padding: 20px;
  margin-top: 100px;
  font-size: 30px;
  justify-content: center;
}

.subheadingBlognew{
  display: flex;
  justify-content: center;
  color: #fff;
}